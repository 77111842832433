exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artikler-js": () => import("./../../../src/pages/artikler.js" /* webpackChunkName: "component---src-pages-artikler-js" */),
  "component---src-pages-dev-2-js": () => import("./../../../src/pages/dev2.js" /* webpackChunkName: "component---src-pages-dev-2-js" */),
  "component---src-pages-fordel-js": () => import("./../../../src/pages/fordel.js" /* webpackChunkName: "component---src-pages-fordel-js" */),
  "component---src-pages-gjeldsforhandling-js": () => import("./../../../src/pages/gjeldsforhandling.js" /* webpackChunkName: "component---src-pages-gjeldsforhandling-js" */),
  "component---src-pages-hjelp-js": () => import("./../../../src/pages/hjelp.js" /* webpackChunkName: "component---src-pages-hjelp-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-innsendt-js": () => import("./../../../src/pages/innsendt.js" /* webpackChunkName: "component---src-pages-innsendt-js" */),
  "component---src-pages-om-js": () => import("./../../../src/pages/om.js" /* webpackChunkName: "component---src-pages-om-js" */),
  "component---src-pages-personvern-js": () => import("./../../../src/pages/personvern.js" /* webpackChunkName: "component---src-pages-personvern-js" */),
  "component---src-pages-refinans-js": () => import("./../../../src/pages/refinans.js" /* webpackChunkName: "component---src-pages-refinans-js" */),
  "component---src-pages-sok-js": () => import("./../../../src/pages/sok.js" /* webpackChunkName: "component---src-pages-sok-js" */),
  "component---src-pages-ulike-lan-js": () => import("./../../../src/pages/ulike-lan.js" /* webpackChunkName: "component---src-pages-ulike-lan-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-testimonial-js": () => import("./../../../src/templates/testimonial.js" /* webpackChunkName: "component---src-templates-testimonial-js" */)
}

